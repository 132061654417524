import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  I18nextContext,
  Trans,
  useTranslation
} from 'gatsby-plugin-react-i18next';
import { partition } from 'lodash';
import { theme } from '../../themes/default';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Seo from '../../components/seo';
import Layout from '../../components/layout/layout';
import Newsletter from '../../components/newsletter';
import getCurrentTranslations from '../../components/contentful-translator';
import { PageSection, SectionWrapper } from '../../components/global/flexbox';
import { SectionHeader } from '../../components/typography';
import { Highlight } from './components/highlight';
import { HighlightWrapper } from './components/highlightWrapper';

import { BtnWrapper, Flex, PageWrapper } from './styled.components';
import BgImg from '../../images/twoToneTwo.png';
import { InhubButton } from '../../components/global/buttons';
import { Page } from '../../components/global/page';

export default function News({ data }) {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  const news = getCurrentTranslations(data.news.edges, language);
  const articles = getCurrentTranslations(data.articles.edges, language);
  const interviews = getCurrentTranslations(data.interviews.edges, language);
  const workshops = partition(
    getCurrentTranslations(data.workshops.edges, language),
    (elem) => new Date() < new Date(elem.node.data)
  );
  //PLEASE REFACTOR IT FOR THE LOVE OF GOD, DO IT IN THE NEXT PR, THIS IS REALLY WRONG/
  news.sort((a, b) => {
    const typeA = a.node.type;
    const typeB = b.node.type;
    if (typeA < typeB) {
      return -1;
    } else if (typeA > typeB) {
      return 1;
    }
    return 0;
  });
  workshops[1] = workshops[1].reverse();
  const pageData = [
    {
      header: t`news.main.header`,
      type: 'news',
      link: '/news',
      data: news,
      loadmore: false
    },
    {
      header: t`news.workshop.header`,
      link: '/workshops',
      type: 'workshops',
      data: workshops,
      loadmore: false
    },
    {
      header: t`news.interviews.header`,
      link: '/interviews',
      type: 'interviews',
      data: interviews,
      loadmore: false
    },
    {
      header: t`news.article.header`,
      link: '/articles',
      type: 'articles',
      data: articles,
      loadmore: false
    }
  ];

  return (
    <Layout withHeader={false}>
      <Seo title={t`news.seo.header`} description={t`news.seo.description`} />
      <Page className="news" style={{ marginLeft: 0, marginRight: 0 }}>
        <ThemeProvider theme={theme}>
          <PageWrapper>
            {pageData.map((section) => {
              return (
                <HighlightWrapper
                  key={section.type}
                  {...section}
                  title={<Trans t={t}>{section.header}</Trans>}
                  component={<Highlight {...section} />}
                />
              );
            })}
            <PageSection className="two-tone" style={{ marginTop: '3rem' }}>
              <SectionWrapper>
                <Flex className="500" bg_img={BgImg}>
                  <div className="white">
                    <span>{t`news.cooperate.text`}</span>
                    <SectionHeader>{t`news.two-tone.header`}</SectionHeader>
                    <p>{t`news.two-tone.text`}</p>
                    <BtnWrapper>
                      <InhubButton
                        type="solid"
                        to="/contact"
                        text={t`news.write-to-us-text`}
                        icon={faArrowRight}
                      />
                      <InhubButton
                        type="hollow"
                        to="/career"
                        text="Dowiedz się więcej"
                        icon={faArrowRight}
                      />
                    </BtnWrapper>
                  </div>
                  <div className="blue" id="blue">
                    <SectionHeader>{t`news.two-tone.header-two`}</SectionHeader>
                    <p>{t`news.two-tone.text-two`}</p>
                    <Newsletter />
                  </div>
                </Flex>
              </SectionWrapper>
            </PageSection>
          </PageWrapper>
        </ThemeProvider>
      </Page>
    </Layout>
  );
}
