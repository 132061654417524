import { graphql } from 'gatsby';
import News from '../../views/news';

export default News;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    articles: allContentfulArticle(sort: { order: DESC, fields: publishDate }) {
      edges {
        node {
          title
          category
          slug
          titleInfo
          publishDate
          authors {
            title
            file {
              url
            }
          }
          thumbnail {
            title
            file {
              url
            }
          }
          readTime
          node_locale
        }
      }
    }
    workshops: allContentfulWarsztaty(sort: { order: ASC, fields: data }) {
      edges {
        node {
          title
          type
          link
          place
          slug
          description {
            description
          }
          data
          thumbnail {
            title
            file {
              url
            }
          }
          node_locale
          agenda {
            startTime
            panelLength
            icon
            isBreak
            name
            speaker {
              id
            }
          }
          speakers {
            id
            name
            description {
              description
            }
            photo {
              file {
                url
              }
            }
            linkedin
          }
          button
        }
      }
    }

    interviews: allContentfulWywiady(sort: { order: DESC, fields: sortDate }) {
      edges {
        node {
          title
          type
          createdAt
          link
          authors
          thumbnail {
            title
            file {
              url
            }
          }
          node_locale
        }
      }
    }
    news: allContentfulNews(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          altTItle
          contentful_id
          createdAt
          readTime
          title
          type
          text {
            raw
          }
          thumbnail {
            file {
              url
            }
          }
          authors {
            title
            file {
              fileName
              url
            }
          }
          node_locale
        }
      }
    }
  }
`;
