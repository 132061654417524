import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { StyledButton } from './styled.components';

export function InhubButton({ type, to, target, text, icon }) {
  return (
    <StyledButton className={type} to={to} target={!target ? null : target}>
      <span>{text}</span>
      {!icon ? null : <FontAwesomeIcon icon={icon} />}
    </StyledButton>
  );
}
